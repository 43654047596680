import React from "react";
import './Bdetail.scss';

const Bdetail7 = () => {
    return(
        <div className="Bdetailcont">
            <div className="Bdetailinner">
                <div className="Bdetailtop">
                    <div className="Bdetailtitle">
                        <h2>구원에 관한 문제의 구절들</h2>
                        <hr/>
                    </div>
                </div>
                <div className="Bdetailbody">
                    
                    <div className="Bdetailtext">
                        <p>구원에 관한 문제의 구절들이 출간되었습니다.<br />교민 여러분들의 많은 관심 바랍니다.</p>
                    </div>
                    <div className="Bdetail7img">

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Bdetail7;