import React from "react";
import './Adetail.scss';

const Adetail1 = () => {
    return(
        <div className="Adetailcont">
            <div className="Adetailinner">
                <div className="Adetailtop">
                    <div className="Adetailtitle">
                        <h2>청/장년 Teaching 훈련</h2>
                        <hr/>
                    </div>
                </div>
                <div className="Adetailbody">
                    
                    <div className="Adetailtext">
                        <p>이번 년도 청년/장년 Teaching 훈련이 실행될 예정입니다.<br />다양한 프로그램과 활동이 준비되어있으니, 많은 관심바랍니다.<br />참가를 희망하시거나 궁금한 점이 있는 분들께서는 담당 선생님과 상담해 주시길바랍니다.<br /><br />시작일시 : 2024년 2월 24일</p>
                    </div>
                    <div className="Adetail1img">

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Adetail1;