import React from "react";
import { NavLink } from "react-router-dom";
import "./Announce.scss"

const announce = () => {
    return(
        <div className="announcecont">
            <div className="announceinner">
                <div className="announcetop">
                    <div className="announcetitle">
                        <h2>공지</h2>
                    </div>
                </div>
                <div className="announcebody">
                    <NavLink to={"/announce/1"}>
                        <div className="card" style={{ textDecoration: "none", color: "black"}}>
                            <div className="cardtext">
                                <h3>청/장년 Teaching 훈련</h3>
                                <p>2024-02-24</p>
                                <span></span>
                            </div>
                            <div className="cardimg1">                
                            </div>
                        </div>
                    </NavLink>
                    <NavLink to={"/announce/2"}>
                        <div className="card" style={{ textDecoration: "none", color: "black"}}>
                            <div className="cardtext">
                                <h3>찬양대 연습</h3>
                                <p>2024-02-11</p>
                                <span>파트별 연습 확인 바랍니다.</span>
                            </div>
                            <div className="cardimg2">                
                            </div>
                        </div>
                    </NavLink>
                    <NavLink to={"/announce/3"}>
                        <div className="card" style={{ textDecoration: "none", color: "black"}}>
                            <div className="cardtext">
                                <h3>거리설교</h3>
                                <p>2024-02-10</p>
                                <span>참여시 따뜻한 옷차림등 보온 대비 당부드립니다.</span>
                            </div>
                            <div className="cardimg3">                
                            </div>
                        </div>
                    </NavLink>
                </div>
            </div>
        </div>
    )
}

export default announce;