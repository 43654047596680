import React from 'react';
import { Carousel } from "react-responsive-carousel";
import Stack from '@mui/material/Stack';
import { ThemeProvider, Typography, createTheme } from "@material-ui/core";
import './CenterCarousel.scss';


const CenterCarousel = [
    {
        title: "선교여행",
        desc: ["한국방문(2024.4.11 ~ 4.31) - 노재석형제님, 김정목형제님"],
        image: "/images/test1.png",
    },
    {
        title: "쥬빌리(부흥회)",
        desc: ["KJV 쥬빌리(2024.3.29 ~ 31)"],
        image: "/images/test1.png",
    },
    {
        title: "선교여행",
        desc: ["아시아사역 대전 미팅(2023. 10. 7 ~ 9) - 김경환목사님, 김주연선교사님"],
        image: "/images/test1.png",
    },
    {
        title: "Summer Camp",
        desc: ["미국 LA SummerCamp(2021)"],
        image: "/images/test1.png",
    },    
];

const theme = createTheme({
    typography: {
        title: {
            fontsize: 20,
        },
        desc: {
            fontsize: 17,
        },
    },    
});

const MidCarousel = () => {
    return (
        <div className="Carousel">
                        <Carousel
                        showArrows={true}
                        centerMode={true}
                        centerSlidePercentage={30}
                        showThumbs={false}
                        showStatus={false}
                        autoPlay={true}
                        infiniteLoop={true}
                        >
                            {CenterCarousel.map((content) => (
                                <>                                
                                <div className='Carouseltext'>
                                    <Typography variant="h3" color="black">
                                        {content.title}
                                    </Typography>
                                    {content.desc.map((description) => (
                                        <li>{description}</li>
                                    ))}
                                </div>
                                <div className='Carouselimage'>
                                    <img src={content.image}></img>
                                </div>
                                </>
                            ))}
                        </Carousel>
                    </div>
    );
}

export default MidCarousel;
