import React from "react";
import './Funding.scss';

const funding = () => {
    return(
        <div className="fundingcont">
            <div className="fundinginner">
                <div className="fundingtop">
                   <div className="fundingtoptxt">
                    <h2>설교영상을 시청하시는 성도분들께서 사역을 지원하기 희망하시는 분들이<br/>계시기에 헌금하실 수 있는 방법을 공지하는 바입니다.</h2>
                    </div> 
                </div>
                <div className="fundingbody">
                    <p>헌금은 1) 우편과, 2) 온라인(PayPal), 3) 계좌이체 의 방법이 있습니다.</p>
                    <span>1. 우편발송 주소(미국) : Bible Baptist Church Int'l, P.OP.Box 267, Stanton, CA90680</span>
                    <p>
                        1) 체크로 헌금, 발송시 : (pay to the order : BBCI)로 기재<br/>
                        2) 체크로 책값, 발송시 : (pay to the order : WOGPS)로 기재
                    </p>
                    <span>2. 한국계좌로 송금</span>
                    <p>1) 은행명 : 하나은행<br/>
                        2) 수취인 : 바이블뱁티스트처치인터내셔날
                        3) 수취인 계좌번호 : 165-910071-97104
                    </p>
                    <span>3. 온라인 페이팔(PayPal)로 송금</span>
                    <button>후원하기</button>
                    <p className="arrow_box">점검중입니다</p>
                </div>
            </div>
        </div>
    )
}
export default funding;