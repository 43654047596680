import React from "react";
import './Bdetail.scss';

const Bdetail4 = () => {
    return(
        <div className="Bdetailcont">
            <div className="Bdetailinner">
                <div className="Bdetailtop">
                    <div className="Bdetailtitle">
                        <h2>Summer Camp</h2>
                        <hr/>
                    </div>
                </div>
                <div className="Bdetailbody">
                    
                    <div className="Bdetailtext">
                        <p>올 여름 미국 LA에서 summer camp가 진행될 예정입니다.<br />관심 있는 교민께서는 담당 선생님과 상담하여 주시길 바랍니다.<br />교민 여러분들의 많은 참여바랍니다.<br /><br />진행일시 : 2024년 6월 17일 ~ 6월 21일</p>
                    </div>
                    <div className="Bdetail4img">

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Bdetail4;