import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { NavLink } from "react-router-dom";
import "./Vision.scss"


const vision = () => {
    return(
        <div className="visioncont">
            <div className="visioninner">
                <div className="visionlink">
                    <li><NavLink to="/introduction/we"  style={{ textDecoration: "none", fontSize: "20px", color: "#797979"}}>우리소개</NavLink></li>
                    <li><NavLink to="/introduction/vision"  style={{ textDecoration: "none", fontSize: "20px", color: "#3C76B4"}}>사역비전</NavLink></li>
                    <li><NavLink to="/introduction/root"  style={{ textDecoration: "none", fontSize: "20px", color: "#797979"}}>믿음의 뿌리</NavLink></li>
                </div>
                <div className="visiontop">
                    <div className="visiontitle">
                        <h2>소개</h2>
                        <h3>사역비전</h3>
                    </div>
                    <div className="visiondesc">
                        <h2>BBCI 사역의 비전</h2>                        
                    </div>                    
                </div>
                <div className="visiongrid">
                    <div className="visionitem visionitem1">
                        <h2>HALL OF TRUTH</h2>
                        <span>진리의 전당인교회의 본분</span>
                        <p>오직 하나님만을 영화롭게 하는 모델 교회<br/>진리를 전파하는 그리스도의 군사 양성</p>
                    </div>
                    <div className="visionitem visionitem2">
                        <h2>BIBLE ONLY</h2>
                        <span>오로지 성경에만 근거한 사역</span>
                        <p>하나님께서 저에게 주신 목회의 비전은 양적으로 교회를 성장시켜 대형 교회를 함으로써 사람들을 많이 모으는 것이 아니었습니다. 마지막 때에는 사람들이 가려운 귀를 즐겁게<br/>
                        해줄 교사들을 많이 둔다고 성경은 말씀하며 세상에는 이미 그런 대형교회(mega church)가 많이 있고, 그런 교회들의 비성경적 메시지, 만연한 죄와 타락으로 인해 진리의 길이<br/>
                        비방을 받고 있습니다. 온 세상보다 더 소중한 혼을 순수한 복음으로 구원받게 하고, 잘못된 구원론, 예정론, 잘못된 종말론, 기복신앙, 각종 이단 교리로 멍들고 혼란에 빠져 있는<br/>
                        우리 민족에게 구원의 메시지를 올바로 전하고 바른 교리를 가르쳐 하나님을 바로 섬기고 죄와 세상으로부터 성별된 능력있는 그리스도의 군사를 양성한다는 목표아래, 주님<br/>
                        오실 때가 교회사 2천년 그 어느 때보다도 가까운 이때에 주님의 오심을 뜨겁게 사모하는 성도들을 양육하고 있습니다.</p>
                    </div>
                    <div className="visionitem visionitem3">
                        <h2>Soul Winning Ministries around the World</h2>
                        <span>소중한 혼의 구원</span>
                        <p>처음 사역을 시작한 곳인 캘리포니아 애너하임부터 엘에이 한인타운을 거쳐 현재 교회는 온타리오시에 위치하고 있으며, 이중언어 사역을 통해 모든 민족을 대상으로 사역하고<br/>
                        있습니다. 지역 교회를 중심으로 성도들과 함께 가가호호 방문하며 매주 거리에 나가 복음을 전파하고 전도지를 나누어 주며, 활발한 미디어 사역(주요 일간지 게재, 라디오, 인터넷,<br/>
                        유투브 방송)을 통해 전 세계 수십만 명에게 복음과 진리를 전파하여 이를 통해 귀한 혼들이 구원받는 역사가 끊임없이 일어나고 있습니다. 육신으로 오신 창조주 하나님이시며 구<br/>
                        원의 대장(Captain of our salvation)이신 주 예수 그리스도께 모든 감사와 영광을 돌립니다.</p>
                    </div>
                    <div className="visionitem visionitem4">
                        <h2>Fellowship and Srevice of Saints</h2>
                        <span>성도의 섬김과 교제</span>
                        <p>모든 성도들은 자발적으로 자신이 받은 은사에 따라, 믿음의 분량에 따라 사역의 일부를 감당합니다. 혼의 구원을 받은 후에도 육신을 입고 살기에 날마다 자신을 죽은 자로 여기며<br/>
                        말씀 가운데 도전과 위로를 나누는 사랑의 교제를 실천합니다. 간증과 도전, 위로와 권면으로 성도들은 광야를 지나는 것 같은 믿음 생활 속에서 새 힘을 얻고 기쁨과 감사가 넘치는<br/>
                        삶을 경험합니다. 진리를 저버리고 죄와 타협함으로써 능력을 잃고 오히려 세상의 비난과 질타를 받는 오늘날의 교회들 앞에 성경적 교리와 실행에 근거한 모델 교회를 제시한다는<br/>
                        목표 아래, 하늘의 교제를 지상에서 맛볼 수 있게 하는, 성별된 성도들의 아름다운 교제를 누립니다. 행사나 활동은 최소화되어 성도들의 헌신은 교회 사역의 최우선 과제인 복음<br/>
                        전파를 위해 드려집니다.</p>
                    </div>
                    <div className="visionitem visionitem5">
                        <h2>Second Generation Ministry</h2>
                        <span>2세 사역</span>
                        <p>우리의 자녀들이 말씀으로 양육받을 때 그 가정도 바로 설 수 있으며, 가정들이 건강하고 강성할 때 교회도 강성해질 수 있습니다. 오늘날 마약과 무서운 타락으로 물든 청소년 문화<br/>
                        속에 노출되어 있는 자녀들이 또래의 압력에 흔들리지 않고 자신의 믿음을 지킬 수 있어서 어려서부터 사역의 소중함을 알고 자신의 시간과 능력을 활용하여 복음 전파 사역에<br/>
                        동참합니다. 사회에서 좋은 간증을 갖고 영향력 있는 그리스도인으로서 사는 것이 중요하기에 학생의 본분에 합당하게 최선을 다해서 공부하고 진학하도록 지도합니다.<br/>
                        록 뮤직으로 일관된 오늘날의 CCM 음악이 아닌, 클래식 음악 악기 연주와 아름다운 찬양으로 교회 찬양 사역에 참여합니다.</p>
                    </div>                        
                </div>                
            </div>
        </div>
    )
}

export default vision;