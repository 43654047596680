import React from "react";
import { NavLink } from "react-router-dom";
import "./News.scss"

const News = () => {
    return(
        <div className="newscont">
            <div className="newsinner">
                <div className="newstop">
                    <div className="newstitle">
                        <h2>교회 소식</h2>
                    </div>
                </div>
                <div className="newsbody">
                    <NavLink to={"/news/1"}>
                        <div className="card" style={{ textDecoration: "none", color: "black"}}>
                            <div className="cardtext">
                                <h3>선교여행</h3>
                                <p>2024-02-29</p>
                                <span>한국방문(2024. 04. 31) - 노재석형제님, 김정목형제님</span>
                            </div>
                            <div className="cardimg1">                
                            </div>
                        </div>
                    </NavLink>
                    <NavLink to={"/news/2"}>
                        <div className="card" style={{ textDecoration: "none", color: "black"}}>
                            <div className="cardtext">
                                <h3>쥬빌리(부흥회)</h3>
                                <p>2024-02-29</p>
                                <span>KJV 쥬빌리(2024.3.29 ~ 31)</span>
                            </div>
                            <div className="cardimg2">                
                            </div>
                        </div>
                    </NavLink>
                    <NavLink to={"/news/3"}>
                        <div className="card" style={{ textDecoration: "none", color: "black"}}>
                            <div className="cardtext">
                                <h3>선교여행</h3>
                                <p>2024-02-29</p>
                                <span>아시아사역 대전 미팅(2023.10.7 ~ 9) - 김경환목사님, 김주연선교사님</span>
                            </div>
                            <div className="cardimg3">                
                            </div>
                        </div>
                    </NavLink>
                    <NavLink to={"/news/4"}>
                        <div className="card" style={{ textDecoration: "none", color: "black"}}>
                            <div className="cardtext">
                                <h3>Summer Camp</h3>
                                <p>2024-02-29</p>
                                <span>미국 LA Summer Camp</span>
                            </div>
                            <div className="cardimg4">                
                            </div>
                        </div>
                    </NavLink>
                    <NavLink to={"/news/5"}>
                        <div className="card" style={{ textDecoration: "none", color: "black"}}>
                            <div className="cardtext">
                                <h3>하나님의 경륜 책자</h3>
                                <p>-</p>
                                <span>출간 예정</span>
                            </div>
                            <div className="cardimg5">                
                            </div>
                        </div>
                    </NavLink>
                    <NavLink to={"/news/6"}>
                        <div className="card" style={{ textDecoration: "none", color: "black"}}>
                            <div className="cardtext">
                                <h3>전도지</h3>
                                <p>-</p>
                                <span>하나님의 사랑/하나님의 구원계획</span>
                            </div>
                            <div className="cardimg6">                
                            </div>
                        </div>
                    </NavLink>
                    <NavLink to={"/news/7"}>
                        <div className="card" style={{ textDecoration: "none", color: "black"}}>
                            <div className="cardtext">
                                <h3>구원에 관한 문제의 구절들</h3>
                                <p>-</p>
                                <span></span>
                            </div>
                            <div className="cardimg7">                
                            </div>
                        </div>
                    </NavLink>
                    <NavLink to={"/news/8"}>
                        <div className="card" style={{ textDecoration: "none", color: "black"}}>
                            <div className="cardtext">
                                <h3>성경을 나누면 보이는 진리들</h3>
                                <p>-</p>
                                <span></span>
                            </div>
                            <div className="cardimg8">                
                            </div>
                        </div>
                    </NavLink>
                    <NavLink to={"/news/9"}>
                        <div className="card" style={{ textDecoration: "none", color: "black"}}>
                            <div className="cardtext">
                                <h3>적그리스도의표 666 때문에 걱정하십니까?</h3>
                                <p>2024-02-29</p>
                                <span></span>
                            </div>
                            <div className="cardimg9">                
                            </div>
                        </div>
                    </NavLink>
                    <NavLink to={"/news/10"}>
                        <div className="card" style={{ textDecoration: "none", color: "black"}}>
                            <div className="cardtext">
                                <h3>구원 이후의 삶</h3>
                                <p>-</p>
                                <span></span>
                            </div>
                            <div className="cardimg10">                
                            </div>
                        </div>
                    </NavLink>
                    <NavLink to={"/news/11"}>
                        <div className="card" style={{ textDecoration: "none", color: "black"}}>
                            <div className="cardtext">
                                <h3>라디오 방송</h3>
                                <p>-</p>
                                <span>김경환목사 설교 송출/LA,뉴욕.뉴저지,애틀란타</span>
                            </div>
                            <div className="cardimg11">                
                            </div>
                        </div>
                    </NavLink>
                    <NavLink to={"/news/12"}>
                        <div className="card" style={{ textDecoration: "none", color: "black"}}>
                            <div className="cardtext">
                                <h3>라디오 앱</h3>
                                <p>-</p>
                                <span></span>
                            </div>
                            <div className="cardimg12">                
                            </div>
                        </div>
                    </NavLink>
                    <NavLink to={"/news/13"}>
                        <div className="card" style={{ textDecoration: "none", color: "black"}}>
                            <div className="cardtext">
                                <h3>TV 방송</h3>
                                <p>-</p>
                                <span></span>
                            </div>
                            <div className="cardimg13">                
                            </div>
                        </div>
                    </NavLink>
                </div>
            </div>
        </div>
    )
}

export default News;