import React from "react";
import './Bdetail.scss';

const Bdetail13 = () => {
    return(
        <div className="Bdetailcont">
            <div className="Bdetailinner">
                <div className="Bdetailtop">
                    <div className="Bdetailtitle">
                        <h2>TV 방송</h2>
                        <hr/>
                    </div>
                </div>
                <div className="Bdetailbody">
                    
                    <div className="Bdetailtext">
                        <p>온디맨드 tv채널에서 방송사역을 송출합니다. 북미,남미지역에서 시청 가능하며 교민 여러분들의 많은 관심 바랍니다. </p>
                    </div>
                    <div className="Bdetail13img">

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Bdetail13;