import React from "react";
import './Footer.scss';
import { NavLink } from "react-router-dom";

const tax = "https://www.nts.go.kr/"

const people = "https://www.acrc.go.kr/"

const bibleradio = "https://koreanradiobibledoctor.com/"

const Footer = () => {
    return(
        <footer className="footercont">
            <div className="footerinner">
                <div className="folink">
                    <div className="tax"  onClick={() => {window.open(tax)}} />
                    <div className="people" onClick={() => {window.open(people)}} />
                </div>
                <div className="fomenu">
                    <div className="fomenu1">
                        <span>소개</span>
                        <li><NavLink to={"/introduction/we"} style={{ textDecoration: "none", color: "black"}}>단체 소개</NavLink></li>
                        <li><NavLink to={"/introduction/root"} style={{ textDecoration: "none", color: "black"}}>담임목사 믿음의 뿌리</NavLink></li>
                        <li><NavLink to={"/introduction/vision"} style={{ textDecoration: "none", color: "black"}}>사역비전</NavLink></li>                        
                    </div>
                    <div className="fomenu1">
                        <span>공지사항</span>
                        <li><NavLink to={"/announce"} style={{ textDecoration: "none", color: "black"}}>공지/알림</NavLink></li>                        
                    </div>
                    <div className="fomenu1">
                        <span>교회소식</span>
                        <li><NavLink to={"/news"} style={{ textDecoration: "none", color: "black"}}>전체 소식</NavLink></li>
                        <li onClick={() => {window.open(bibleradio)}} style={{ cursor: "pointer"}}>라디오 사역</li>
                    </div>
                    <div className="fomenu1">
                        <span>자료실</span>
                        <li><NavLink to={"/library/witness"}  style={{ textDecoration: "none", color: "black"}}>성도들의 간증</NavLink></li>
                        <li><NavLink to={"/library/find"}  style={{ textDecoration: "none", color: "black"}}>바른 진리를 찾아서</NavLink></li>
                    </div>                    
                </div>  
                <div className="foinfo">
                    <p className="foinfo1">바이블뱁티스트터치인터내셔날(BIBLE BAPTIST CHURCH INTERNATIONAL)<br/>주소 : 서울특별시 강서구 마곡중앙4로 10, 에이동 415호 |
                    사업자등록번호 : 250185-0000537</p>
                    <p className="foinfo2">바이블뱁티스트터치인터내셔날 Corp. All rights reserved.</p>
                </div>              
            </div>
        </footer>
    )
}

export default Footer;