import React from "react";
import './Adetail.scss';

const Adetail2 = () => {
    return(
        <div className="Adetailcont">
            <div className="Adetailinner">
                <div className="Adetailtop">
                    <div className="Adetailtitle">
                        <h2>찬양대 연습</h2>
                        <hr/>
                    </div>
                </div>
                <div className="Adetailbody">
                    
                    <div className="Adetailtext">
                        <p>이번 연습부터  찬양대 연습이 새롭게 진행될 예정입니다.<br />지난 연습에서 미흡했던 부분들이 많습니다.<br />각 파트의 장께서는 파트별 음원을 확인해주시고, 합동 연습 이전에 파트별 연습을 진행해 주시길 바랍니다.<br /><br />진행일시 : 2024년 2월 11일 
                         </p>
                    </div>
                    <div className="Adetail2img">

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Adetail2;