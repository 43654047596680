import logo from './logo.svg';
import { Route, Routes } from 'react-router-dom';
import Main from './pages/Main';
import We from './pages/introduction/We';
import Root from './pages/introduction/Root';
import Vision from './pages/introduction/Vision';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import News from './pages/news/News'
import Announce from'./pages/announce/Announce';
import Find from './pages/library/Find';
import Witness from './pages/library/Witness';
import Adetail1 from './pages/announce/announcedetail/Adetail01';
import Adetail2 from './pages/announce/announcedetail/Adetail02';
import Adetail3 from './pages/announce/announcedetail/Adetail03';
import Bdetail1 from './pages/news/ndetail/Bdetail01';
import Bdetail2 from './pages/news/ndetail/Bdetail02';
import Bdetail3 from './pages/news/ndetail/Bdetail03';
import Bdetail4 from './pages/news/ndetail/Bdetail04';
import Bdetail5 from './pages/news/ndetail/Bdetail05';
import Bdetail6 from './pages/news/ndetail/Bdetail06';
import Bdetail7 from './pages/news/ndetail/Bdetail07';
import Bdetail8 from './pages/news/ndetail/Bdetail08';
import Bdetail9 from './pages/news/ndetail/Bdetail09';
import Bdetail10 from './pages/news/ndetail/Bdetail10';
import Bdetail11 from './pages/news/ndetail/Bdetail11';
import Bdetail12 from './pages/news/ndetail/Bdetail12';
import Bdetail13 from './pages/news/ndetail/Bdetail13';
import Funding from './pages/funding/Funding';
import './App.css';

function App() {
  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path='/' element={<Main></Main>}></Route>
        <Route path='/introduction/we' element={<We />} />
        <Route path='/introduction/root' element={<Root />} />
        <Route path='/introduction/vision' element={<Vision />} />
        <Route path='/news' element={<News />} />
        <Route path='/news/1' element={<Bdetail1 />} />
        <Route path='/news/2' element={<Bdetail2 />} />
        <Route path='/news/3' element={<Bdetail3 />} />
        <Route path='/news/4' element={<Bdetail4 />} />
        <Route path='/news/5' element={<Bdetail5 />} />
        <Route path='/news/6' element={<Bdetail6 />} />
        <Route path='/news/7' element={<Bdetail7 />} />
        <Route path='/news/8' element={<Bdetail8 />} />
        <Route path='/news/9' element={<Bdetail9 />} />
        <Route path='/news/10' element={<Bdetail10 />} />
        <Route path='/news/11' element={<Bdetail11 />} />
        <Route path='/news/12' element={<Bdetail12 />} />
        <Route path='/news/13' element={<Bdetail13 />} />
        <Route path='/announce' element={<Announce />} />
        <Route path='/announce/1' element={<Adetail1 />} />
        <Route path='/announce/2' element={<Adetail2 />} />
        <Route path='/announce/3' element={<Adetail3 />} />
        <Route path='/library/find' element={<Find />} />
        <Route path='/library/witness' element={<Witness />} />
        <Route path='/funding' element={<Funding />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
