import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { NavLink } from "react-router-dom";
import "./We.scss"


const We = () => {
    return(
        <div className="wecont">
            <div className="weinner">
                <div className="welink">
                    <li><NavLink to="/introduction/we" style={{ textDecoration: "none", fontSize: "20px", color: "#3C76B4"}}>우리소개</NavLink></li>
                    <li><NavLink to="/introduction/vision" style={{ textDecoration: "none", fontSize: "20px", color: "#797979"}}>사역비전</NavLink></li>
                    <li><NavLink to="/introduction/root" style={{ textDecoration: "none", fontSize: "20px", color: "#797979"}}>믿음의 뿌리</NavLink></li>
                </div>
                <div className="wetop">
                    <div className="wetitle">
                        <h2>소개</h2>
                        <h3>우리 소개</h3>
                    </div>
                    <div className="wedesc">
                        <h2>바이블뱁티스트터치인터내셔날(BBCI)</h2>
                        <p>본 교회는 하나님의 말씀이 가르치시는대로 신약 성경의 교리적 원칙을 교육, 실행하는 지역교회입니다.<br />성도들이 직접 모임을 구성하며 자치적으로 운영하는 독립 침례교회입니다.</p>
                    </div>                    
                </div>
                <div className="wegrid">
                        <div className="weitem weitem1">
                            <h2>킹제임스성경</h2>
                            <p>해당 성경은 하나님의 말씀을 가장 온전히 보존하고, 바르게 전하는 성경임을 믿습니다.<br />본 교회는 위 성경을 믿음과 행동의 원칙으로 삼습니다.</p>
                        </div>
                        <div className="weitem weitem2">
                            <h2>주 예수 그리스도</h2>
                            <p>본 교회는 예수 그리스도께서 육신으로 오신 하나님임을 믿으며,<br/>당신의 탄생, 죽음, 부활과 승천을 긍정합니다.<br />또한 약속된 재림과 복권을 믿습니다.</p>
                        </div>
                        <div className="weitem weitem3">
                            <h2>성도</h2>
                            <p>본 교회의 구성원들은 주 예수 그리스도를 통하여 증명 된 하나님의 구원을 믿습니다.<br />이를 믿음으로 성령되어 다시 태어난 자가 그리스도의 몸을 이룬다는 사실을 믿습니다.<br />또한 지상에서 주 예수 그리스도의 말씀에 받들어 순종하는 역할임을 믿습니다.</p>
                        </div>
                        <div className="weitem weitem4">
                            <h2>침례, 주의 만찬</h2>
                            <p>본 교회는 신약 교회에 속함으로 위 두 의식을 행합니다. 침례 받음으로서 그리스도인(물 부음 받은자)로 거듭나며,<br />해당 의식을 진행함에 구원받기 위한 수단으로서가 아닌 스스로의 신앙을 공개적으로 간증하기 위함임을 인지합니다.<br />또한 거듭난 그리스도인은 주 예수 그리스도의 죽음을 기념하여 지역교회가 정한 때에 주의 만찬을 진행합니다.</p>
                        </div>
                        <div className="weitem weitem5">
                            <h2>구원</h2>
                            <p>본 교회는 한번 구원받아 다시 태어난 자가 그리스도의 몸을 이룸을 믿는것을 다시 강조하며,<br />그의 구원이 영원함을 믿습니다.</p>
                        </div>
                        <div className="weitem weitem6">
                            <h2>성별된 삶</h2>
                            <p>본 교회는 세상으로 불러냄을 받아 모인 모임으로, 우리의 삶이 세상으로부터 성별되어 경건한 삶을 살아감을 지향합니다.<br />세상의 재물과 명예, 외부의 시선에 시련받지 아니하며, 지식을 앎에 있어 주 예수 그리스도를 아는 것이 가장 존귀한 것임을 믿습니다.</p>
                        </div>
                        <div className="weitem weitem7">
                            <h2>휴거와 재림</h2>
                            <p>본 교회는 머지 않아 주 예수 그리스도께서 당신의 신부인 교회를 데려가심을 믿습니다.<br />그 후 지상에는 다니엘의 70째 주 "야곱의 고난의 때"즉, 대환란이 시작되고, 그것이 지나면 당신께서 신부를 대리고 재림하시어<br />예루살렘의 다윗의 보좌에 앉아 통치하심을 믿습니다.</p>
                        </div>
                </div>
                <div className="webot">
                    <h2>성도의 교제</h2>
                    <p>본 교회는 모든 교회를 그리스도인임을 인정하지 않습니다.<br/>주 예수 그리스도의 은혜와 복음을 믿어 구원받음이 확실한 이들과 교제를 나눕니다.</p>
                    <span>그리스도인의 교제는 서로를 투영함으로 위로와 도전을 공유합니다.<br/>본 교회는 구원받은 하나님의 자녀를 인종, 성별, 국적에 관계없이 항상 환영합니다.</span>
                </div>
            </div>
        </div>
    )
}

export default We;