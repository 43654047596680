import React from "react";
import './Bdetail.scss';

const Bdetail2 = () => {
    return(
        <div className="Bdetailcont">
            <div className="Bdetailinner">
                <div className="Bdetailtop">
                    <div className="Bdetailtitle">
                        <h2>쥬빌리(부흥회)</h2>
                        <hr/>
                    </div>
                </div>
                <div className="Bdetailbody">
                    
                    <div className="Bdetailtext">
                        <p>이번 년도 하반기 KJV쥬빌리가 있을 예정입니다.<br />이번 부흥회는 총 이틀간 진행되며 교민 여러분들의<br />많은 관심과 참여 바랍니다.<br />또한 연계되는 선교사 방문이 4월, 10월 중으로 있을 예정입니다.<br /><br />진행일시 : 2024년 3월 29일 ~ 3월 31일 </p>
                    </div>
                    <div className="Bdetail2img">

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Bdetail2;