import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { NavLink } from "react-router-dom";
import "./Root.scss"


const Root = () => {
    return(
        <div className="rootcont">
            <div className="rootinner">
                <div className="rootlink">
                    <li><NavLink to="/introduction/we"  style={{ textDecoration: "none", fontSize: "20px", color: "#797979"}}>우리소개</NavLink></li>
                    <li><NavLink to="/introduction/vision"  style={{ textDecoration: "none", fontSize: "20px", color: "#797979"}}>사역비전</NavLink></li>
                    <li><NavLink to="/introduction/root"  style={{ textDecoration: "none", fontSize: "20px", color: "#3C76B4"}}>믿음의 뿌리</NavLink></li>
                </div>
                <div className="roottop">
                    <div className="roottitle">
                        <h2>소개</h2>
                        <h3>믿음의 뿌리</h3>
                    </div>
                    <div className="rootdesc">
                        <h2>바이블뱁티스트터치인터내셔날(BBCI)</h2>
                        <p>모든 피조물은 창조주하나님이 만드셨고, 이 첫 번째 뿌리에서 여러 세대를 거쳐 오늘에 이른 것이다.<br/>모든 사람들도 조상이 있듯이 모든 신앙들도 그들의 뿌리가 있는 것이다.</p>                        
                    </div>                    
                </div>
                <div className="rootcontent">
                    <p>본 교회는 bible believers에 뿌리를 둔다.<br/>
                        위 단체는 재 침례교도라고 불렸으며, 사도 때부터 순수한 믿음을 지켜왔다. 이는 수많은 박해를<br/>
                        동반한 고난이었다.<br/><br/>
                        위 단체의 영향으로 미국에는 무수한 침례교회들이 세워졌다.<br/><br/>
                        그 중 가장 강세한 남부 큰 침례교회(Southern Baptist)의 신실한 목사들은 20세기에 들어서<br/>
                        교단의 여러 비성경적 행위로부터 성별하여 신앙의 순수성을 지키기 위하여 독립침례교회<br/>
                        (Independent Baptist Church)들과 신학교를 설립한다.<br/><br/>
                        그러나 시간이 흘러 해당 교회와 학교 또한 하나님의 온전한 말씀을 지키지 않았다.<br/>
                        이에 Peter Ruchman목사의 주도하에 1960년 펜사콜라 성경 연구소<br/>
                        (Pensacola Bible Institute)를 설립하여 그들과 성별한다.<br/>
                        이 판단은 킹제임스성경(King James Bible)에 근거했다.<br/>
                        그는 신실한 그리스도의 군사를 양육하여 전 세계에 킹 제임스 성경의 교리에 따른 신앙을<br/>
                        전파한다.<br/>
                        그 영향으로 대다수의 침례교회들은 킹제임스성경을 설교하게 되었다.<br/><br/>
                        본 목사는 31살의 나이에 구원받아 그 순수한 뿌리를 찾아 미국 독립침례교회에서 생활했다.<br/>
                        그곳에서 하나님의 사역자로 부름받아 성경적인 믿음과 그것을 따르는 신학을 안수<br/>
                        받을 수 있었다.<br/>
                        그 후 LA로 가 한인들에게 그 순수한 믿음과 성경을 전파했다.<br/><br/>
                        주님께서 우리민족을 불쌍히 여겨 이 순수한 믿음과 성경을 열어주시어 미주 전역에 전파되는<br/>
                        현재에 하나님께 감사드린다.<br/>
                        진리를 전파하는 성경적인 믿음을 가진 목사를 비방한 자.<br/>
                        그리고 하나님의 바른 말씀을 부정하며 수 많은 무지한 이들을 지옥으로 보낸 거짓된 목사들은<br/>
                        주님의 말씀을 두려운 마음으로 받아 들이길 기도한다.
                    </p>
                </div>                
            </div>
        </div>
    )
}

export default Root;