import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { NavLink } from "react-router-dom";
import "./Witness.scss"


const witness = () => {
    return(
        <div className="witnesscont">
            <div className="witnessinner">                
                <div className="witnesstop">
                    <div className="witnesslink">
                        <li><NavLink to="/library/witness" style={{ textDecoration: "none", fontSize: "20px", color: "#3C76B4"}}>성도들의간증</NavLink></li>
                        <li><NavLink to="/library/find" style={{ textDecoration: "none", fontSize: "20px", color: "#797979"}}>바른진리를 찾아서</NavLink></li>                        
                    </div>
                    <div className="witnesstitle">
                        <h2>자료실</h2>
                    </div>
                    <div className="witnessdesc">
                        <h2>성도들의 간증</h2>
                    </div>                    
                </div>
                <div className="witnessgrid">
                        <div className="witnessitem witnessitem1">
                            <h2>대환란의 단두대를 기다리던 내가 드디어 찾은 진리</h2>
                        </div>
                        <div className="witnessitem witnessitem2">
                            <h2>마약중독자인 나를 돌아서게한, KJV성경과 은혜복음!</h2>
                        </div>
                        <div className="witnessitem witnessitem3">
                            <h2>강한설교에 이끌린 나의 혼!</h2>
                        </div>
                        <div className="witnessitem witnessitem4">
                            <h2>매질로 버려진 나의 혼!, 영원한 생명을 찾게한 그 댓글!</h2>
                        </div>
                </div>
            </div>
        </div>
    )
}

export default witness;