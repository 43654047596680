import React from "react";
import './Bdetail.scss';

const Bdetail9 = () => {
    return(
        <div className="Bdetailcont">
            <div className="Bdetailinner">
                <div className="Bdetailtop">
                    <div className="Bdetailtitle">
                        <h2>적그리스도의 표 666 때문에 걱정하십니까?</h2>
                        <hr/>
                    </div>
                </div>
                <div className="Bdetailbody">
                    
                    <div className="Bdetailtext">
                        <p>적그리스도의 표 666 때문에 걱정하십니까?가 출간되었습니다.<br />교민 여러분들의 많은 관심 바랍니다.</p>
                    </div>
                    <div className="Bdetail9img">

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Bdetail9;