import React from "react";
import './Bdetail.scss';

const Bdetail11 = () => {
    return(
        <div className="Bdetailcont">
            <div className="Bdetailinner">
                <div className="Bdetailtop">
                    <div className="Bdetailtitle">
                        <h2>라디오 방송</h2>
                        <hr/>
                    </div>
                </div>
                <div className="Bdetailbody">
                    
                    <div className="Bdetailtext">
                        <p>아래 해당하는 지역에서는 김경환목사님께서 진행하시는<br />설교 방송을 청취하실 수 있습니다.<br />해당 지역에 거주하시는 교민 분들꼐서는 많은 청취 부탁드립니다.<br /><br />1.캘리포니아 LA 지역.<br />2.뉴욕/뉴저지 지역.<br />3.애틀란타 지역.<br /><br /></p>
                    </div>
                    <div className="Bdetail11img">

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Bdetail11;