import React from "react";
import './Adetail.scss';

const Adetail3 = () => {
    return(
        <div className="Adetailcont">
            <div className="Adetailinner">
                <div className="Adetailtop">
                    <div className="Adetailtitle">
                        <h2>거리설교</h2>
                        <hr/>
                    </div>
                </div>
                <div className="Adetailbody">
                    
                    <div className="Adetailtext">
                        <p>이번 주말 거리설교가 있을 예정입니다.<br />참가를 희망하시는 분들께서는 잊지말고 준비해주시길 바랍니다.<br />교민 여러분들의 많은 관심과 참여 바랍니다.<br /><br />진행일시  2024년 2월 10일<br />진행장소 : 신촌역 2번 출구</p>
                         
                    </div>
                    <div className="Adetail3img">

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Adetail3;