import React from "react";
import './Bdetail.scss';

const Bdetail3 = () => {
    return(
        <div className="Bdetailcont">
            <div className="Bdetailinner">
                <div className="Bdetailtop">
                    <div className="Bdetailtitle">
                        <h2>선교여행</h2>
                        <hr/>
                    </div>
                </div>
                <div className="Bdetailbody">
                    
                    <div className="Bdetailtext">
                        <p>호주/뉴질랜드 선교여행 : 김주연선교사님, 노재석형제님.<br />일시 : 2024년 2월 1일 ~ 2월 12일.<br />아시아사역 대전 미팅 : 김경환 목사님, 김주연교사님.<br />일시 : 2023년 10월 7일 ~10월 9일</p>
                    </div>
                    <div className="Bdetail3img">

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Bdetail3;