import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { NavLink } from "react-router-dom";
import "./Find.scss"


const find = () => {
    return(
        <div className="findcont">
            <div className="findinner">                
                <div className="findtop">
                    <div className="findlink">
                        <li><NavLink to="/library/witness" style={{ textDecoration: "none", fontSize: "20px", color: "#797979"}}>성도들의간증</NavLink></li>
                        <li><NavLink to="/library/find" style={{ textDecoration: "none", fontSize: "20px", color: "#3C76B4"}}>바른진리를 찾아서</NavLink></li>                        
                    </div>
                    <div className="findtitle">
                        <h2>자료실</h2>
                    </div>
                    <div className="finddesc">
                        <h2>바른 진리를 찾아서</h2>
                    </div>                    
                </div>
                <div className="findgrid">
                        <div className="finditem finditem1">
                            <h2>샤머니즘을 부활시킨 은사주의 목사들. 하나.</h2>
                        </div>
                        <div className="finditem finditem2">
                            <h2>샤머니즘을 부활시킨 은사주의 목사들. 둘. </h2>
                        </div>
                        <div className="finditem finditem3">
                            <h2>샤머니즘을 부활시킨 은사주의 목사들. 셋.</h2>
                        </div>
                        <div className="finditem finditem4">
                            <h2>샤머니즘을 부활시킨 은사주의 목사들. 넷.</h2>
                        </div>
                        <div className="finditem finditem5">
                            <h2>샤머니즘을 부활시킨 은사주의 목사들. 다섯.</h2>
                        </div>
                        <div className="finditem finditem6">
                            <h2>샤머니즘을 부활시킨 은사주의 목사들. 여섯.</h2>
                        </div>
                        <div className="finditem finditem7">
                            <h2>샤머니즘을 부활시킨 은사주의 목사들. 일곱.</h2>
                        </div>
                        <div className="finditem finditem8">
                            <h2>샤머니즘을 부활시킨 은사주의 목사들. 여덟.</h2>
                        </div> 
                        <div className="finditem finditem9">
                            <h2>샤머니즘을 부활시킨 은사주의 목사들. 아홉.</h2>
                        </div> 
                        <div className="finditem finditem10">
                            <h2>샤머니즘을 부활시킨 은사주의 목사들. 열.</h2>
                        </div> 
                        <div className="finditem finditem11">
                            <h2>거짓목사를 분별하는 법. </h2>
                        </div>
                        <div className="finditem finditem12">
                            <h2>잘못된 침례의 교리로써 지옥으로 몰고 가는 거짓 목사들. </h2>
                        </div>
                        <div className="finditem finditem13">
                            <h2>유대인의 침례를 믿고 지옥으로 가는 자들</h2>
                        </div>
                        <div className="finditem finditem14">
                            <h2>미혹의 영에 사로잡힌 오순절 운동 100년의 역사. 하나.</h2>
                        </div>
                </div>                
            </div>
        </div>
    )
}

export default find;