import React from "react";
import './Bdetail.scss';

const Bdetail6 = () => {
    return(
        <div className="Bdetailcont">
            <div className="Bdetailinner">
                <div className="Bdetailtop">
                    <div className="Bdetailtitle">
                        <h2>전도지</h2>
                        <hr/>
                    </div>
                </div>
                <div className="Bdetailbody">
                    
                    <div className="Bdetailtext">
                        <p>전도지 하나님의 사랑과 하나님의 구원계획이 발간되었습니다.<br />교민 여러분들의 많은 관심바랍니다.</p>
                    </div>
                    <div className="Bdetail6img">

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Bdetail6;