import React from "react";
import './Bdetail.scss';

const bibleradioapp = "https://play.google.com/store/apps/details?id=com.koreanradiobibledoctor.app&hl=ko&gl=US"

const Bdetail12 = () => {
    return(
        <div className="Bdetailcont">
            <div className="Bdetailinner">
                <div className="Bdetailtop">
                    <div className="Bdetailtitle">
                        <h2>라디오 앱</h2>
                        <hr/>
                    </div>
                </div>
                <div className="Bdetailbody">
                    
                    <div className="Bdetailtext">
                        <p>아래 링크를 통해 김경환 목사님의 24시간 설교 라디오 앱을 다운받을 수 있습니다.<br />교민 여러분들의 많은 참여 바랍니다. </p>
                        <div className="RadioApp" onClick={() => {window.open(bibleradioapp)}}></div>
                    </div>
                    <div className="Bdetail12img">

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Bdetail12;