import React from "react";
import './Bdetail.scss';

const Bdetail1 = () => {
    return(
        <div className="Bdetailcont">
            <div className="Bdetailinner">
                <div className="Bdetailtop">
                    <div className="Bdetailtitle">
                        <h2>선교여행</h2>
                        <hr/>
                    </div>
                </div>
                <div className="Bdetailbody">
                    
                    <div className="Bdetailtext">
                        <p>한국방문 : 노재석형제님, 김정목형제님.<br />일시 : 2024년 4월 11일 ~ 4월 31일<br /><br />미국에 머무르시던 두 형제분들께서 이번에<br />한국에 방문하실 예정입니다.<br />반가운 마음으로 환영해 주시길 바랍니다.</p>
                    </div>
                    <div className="Bdetail1img">

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Bdetail1;