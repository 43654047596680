import React from "react";
import "./Header.scss";
import { NavLink } from "react-router-dom";

const Header = () => {
  return (
    <header className="headercont">
      <div className="headerinner">
        <NavLink to="/">
          <div className="logo">
            
          </div>
        </NavLink>
        <div className="menu">          
          <ul>
            <NavLink to="/" className="menuname">메인</NavLink>
            <NavLink to="/introduction/we" className="menuname">소개</NavLink>            
            <NavLink to="/announce" className="menuname">공지사항</NavLink>
            <NavLink to="/news" className="menuname" >KBBCI 소식</NavLink>
            <NavLink to="/library/witness" className="menuname">자료실</NavLink>
            <NavLink to="/funding" className="menuname">후원</NavLink>
          </ul>
        </div>
      </div>
    </header>
  )
}

export default Header;