import React from "react";
import './Bdetail.scss';

const Bdetail10 = () => {
    return(
        <div className="Bdetailcont">
            <div className="Bdetailinner">
                <div className="Bdetailtop">
                    <div className="Bdetailtitle">
                        <h2>구원 이후의 삶</h2>
                        <hr/>
                    </div>
                </div>
                <div className="Bdetailbody">
                    
                    <div className="Bdetailtext">
                        <p>구원 이후의 삶이 출간되었습니다.<br />교민 여러분들의 많은 관심 바랍니다.</p>
                    </div>
                    <div className="Bdetail10img">

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Bdetail10;