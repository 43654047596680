import React from "react";
import './Bdetail.scss';

const Bdetail5 = () => {
    return(
        <div className="Bdetailcont">
            <div className="Bdetailinner">
                <div className="Bdetailtop">
                    <div className="Bdetailtitle">
                        <h2>하나님의 경륜 책자</h2>
                        <hr/>
                    </div>
                </div>
                <div className="Bdetailbody">
                    
                    <div className="Bdetailtext">
                        <p>하나님의 경륜을 담은 책자가 출간 예정입니다.<br />상세한 내용은 후속 공지하갰습니다.</p>
                    </div>
                    <div className="Bdetail5img">

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Bdetail5;