import React, { useState } from "react";
import Carousel from "react-material-ui-carousel" ;
import "react-responsive-carousel/lib/styles/carousel.min.css";
import MidCarousel from "../components/CenterCarousel/CenterCarousel";
import { Typography } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import {Tooltip} from "react-tooltip";
import "./Main.scss"

const bibleradio = "https://koreanradiobibledoctor.com/"

const bibleradioapp = "https://play.google.com/store/apps/details?id=com.koreanradiobibledoctor.app&hl=ko&gl=US"

const Lbook = "https://m.blog.naver.com/kjv1611hub/222666888102"

const Rbook = "https://mall.godpeople.com/?Tno=wel2c&key=%B1%E8%B0%E6%C8%AF&plus_key=%B1%E8%B0%E6%C8%AF&maker_no=56123"

const way = "https://map.naver.com/p/search/%EC%84%9C%EC%9A%B8%ED%8A%B9%EB%B3%84%EC%8B%9C%20%EA%B0%95%EC%84%9C%EA%B5%AC%20%EB%A7%88%EA%B3%A1%EC%A4%91%EC%95%994%EB%A1%9C%2010%2C%20%EC%97%90%EC%9D%B4%EB%8F%99%20415%ED%98%B8%20/address/14118421.7770835,4517255.8264729,%EC%84%9C%EC%9A%B8%ED%8A%B9%EB%B3%84%EC%8B%9C%20%EA%B0%95%EC%84%9C%EA%B5%AC%20%EB%A7%88%EA%B3%A1%EC%A4%91%EC%95%994%EB%A1%9C%2010,new?c=16.93,0,0,0,dh&isCorrectAnswer=true"

const CenterNews = [
    {
        title: "선교여행",
        desc: ["한국방문(2024.4.11 ~ 4.31) - 노재석형제님, 김정목형제님"],
        image: "/images/mt2.png",
    },
    {
        title: "쥬빌리(부흥회)",
        desc: ["KJV 쥬빌리(2024.3.29 ~ 31)"],
        image: "/images/jubil.png",
    },
    {
        title: "선교여행",
        desc: ["아시아사역 대전 미팅(2023. 10. 7 ~ 9) - 김경환목사님, 김주연선교사님"],
        image: "/images/mt1.png",
    },
    {
        title: "Summer Camp",
        desc: ["미국 LA SummerCamp(2021)"],
        image: "/images/summercamp.png",
    },    
];

const Item1Carousel = [
    {
        title: "청년/장년 Teaching 훈련(2024. 02. 24)",
        desc: [
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
        ],
    },
    {
        title: "청년/장년 Teaching 훈련(2024. 02. 24)2",
        desc: [
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
        ],
    },
    {
        title: "청년/장년 Teaching 훈련(2024. 02. 24)3",
        desc: [
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
        ],
    },
    {
        title: "청년/장년 Teaching 훈련(2024. 02. 24)4",
        desc: [
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
        ],
    },
];


const Main = () => {
    const [showModal, setShowModal] = useState(false);

    const openModal = () => {
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    return(
        <div className="maincont">
            <div className="maininner">
                <div className="maintitle">
                    <div className="maintitleimg" />
                    <div className="maintitletext">
                        <h2>바이블뱁티스트처치인터내셔날 한국지부</h2>
                    </div>  
                </div>                
                <div className="CenterNewsCont">
                    <h2>KBBCI 소식</h2>
                    <div className="CenterNews">
                        {CenterNews.map((content) => (
                            <>
                            <NavLink to="news" style={{ textDecoration: "none", color: "black"}}>
                            <div className="CenterNewsCard">
                                <div className="CenterNewsText">
                                    <Typography variant="h3" color="black">
                                        {content.title}
                                    </Typography>
                                    {content.desc.map((description) => (
                                        <li>{description}</li>
                                        ))}
                                </div>
                                <div className="CenterNewsImage">
                                    <img src={content.image}></img>
                                </div>
                            </div>
                            </NavLink>
                            </>
                        ))}
                    </div>
                    {/* <MidCarousel /> */}
                </div>
                <div className="books">
                    <h2>책자</h2>
                    <div className="book1" onClick={() => {window.open(Lbook)}}></div>
                    <div className="book2" onClick={() => {window.open(Rbook)}}></div>
                </div>
                <div className="reference">
                    <h2>자료실</h2>
                    <NavLink to="/library/witness"><div className="ref1">성도들의 간증</div></NavLink>
                    <NavLink to="/library/find"><div className="ref2">바른 진리를 찾아서</div></NavLink>
                </div>
                {/* <div className="videos">
                    <h2>사역</h2>
                    <div className="video1"></div>
                    <div className="video2"></div>
                    <div className="video3"></div>
                    <div className="video4"></div>
                </div> */}
                <div className="radio" onClick={() => {window.open(bibleradio)}}>
                    <li>라디오 앱 홍보 페이지</li>
                </div>
                <div className="RadioApp" onClick={() => {window.open(bibleradioapp)}}></div>
                {/* <div className="topgrid">
                    <div className="item item1">
                        <Carousel cycleNavigation={true} navButtonAlwaysVisible={true}>
                            {Item1Carousel.map((content) => (
                                <>
                                    <Typography variant="h3" color="black">
                                        {content.title}
                                    </Typography>
                                    {content.desc.map((description) => (
                                        <li>{description}</li>
                                    ))}
                                </>
                            ))}
                        </Carousel>
                    </div>                    
                        <button className="item item2">  
                            <NavLink to="/introduction/we">                      
                                <li>단체 소개</li>
                            </NavLink>
                        </button>                    
                    <div className="item item3">
                        <li className="thanks">후원 감사합니다</li>
                        <li className="people">1,000 명</li>
                        <button>후원하기</button>
                        <p className="arrow_box">점검중입니다</p>
                    </div>
                </div> */}
                <div className="way">
                    <div className="wayimg" onClick={() => {window.open(way)}}/> 
                    <span>찾아 오시는 길</span>                    
                    <li>서울특별시 강서구 마곡중앙4로 10, 에이동 415호 </li>  
                    <li>지하철 마곡역 5번출구 도보 1분</li> 
                              
                </div>
                
            </div>
        </div>
    )
}

export default Main;